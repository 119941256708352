<template>
  <div class="cookies_banner py-5 py-lg-4">
    <button type="button" class="close" @click="cookiesChoice(false)"><i class="fa-solid fa-xmark me-1"></i>Fermer</button>
    <div class="container d-flex align-items-center">
      <img src="@/assets/cookies.svg" alt="" class="d-none d-md-block">
      <div class="ps-md-5 text-center text-md-start">
        <h2 class="h4 text-md-start">Gestion des cookies</h2>
        <p>Afin de me permettre d'améliorer la qualité de mes services, vous pouvez accepter les cookies. Ces cookies sont utilisés à des fins statistiques, pour mesurer le trafic par exemple. Les cookies utilisés sont ceux de <a href="https://metricool.com/fr/" target="_blank" rel="noopener noreferrer">metricool.com</a> et de <a href="https://marketingplatform.google.com/about/analytics/" target="_blank" rel="noopener noreferrer">analytics.google.com</a>.</p>
        <div class="d-flex justify-content-center justify-content-md-start mt-4">
          <button type="button" class="btn btn-secondary me-2" @click="cookiesChoice(false)">Non merci</button>
          <button type="button" class="btn btn-primary" @click="cookiesChoice(true)">J'accepte les cookies</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookiesBanner",

  methods: {
    cookiesChoice(accepted) {
      localStorage.setItem('acceptCookies', accepted);
      this.$emit('save-cookies-choices', accepted);
    }
  }
};
</script>
