<script>
export default {
  methods: {
    async sitemapGenerator() {
      const baseUrl = "https://yaniskocher.fr";
      let articles = [];
      await this.$api.get("articles-published").then(({ data }) => (articles = data));

      let routes = [];
      this.$router.options.routes.forEach((route) => {
        if (route.sitemap) {
          routes.push({
            loc: baseUrl + route.path,
            lastmod: route.sitemap.lastmod,
            priority: route.sitemap.priority,
          });
        } else if (route.children) {
          route.children.forEach((children) => {
            if (children.sitemap) {
              routes.push({
                loc: baseUrl + route.path,
                lastmod: children.sitemap.lastmod,
                priority: children.sitemap.priority,
              });
            }
          });
        }
      });

      articles.forEach((article) => {
        routes.push({
          loc: baseUrl + "/blog/" + article.slug,
          lastmod: new Date(article.created_at).toISOString(),
          priority: 1,
        });
      });

      const urls = routes.map(
        (route) =>
        `
        <url>
          <loc>${route.loc}</loc>
          <lastmod>${route.lastmod}</lastmod>
          <priority>${route.priority}</priority>
        </url>
        `
      );

      alert("Copie du sitemap");
      navigator.clipboard
        .writeText(
          `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
            ${urls.join("\n")}
           </urlset>`
        )
        .then(() => {
          console.log("Sitemap copié avec succès !");
        })
        .catch((err) => {
          console.error("Impossible de copier le sitemap : ", err);
        });
    },
  },
};
</script>
