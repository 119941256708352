// set fade effect
export function setFadeEffect() {
  setFadeUp();
  setFadeUpList();
}

function setFadeUp() {
  const items = document.querySelectorAll(".fade-up");

  const active = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("inview");
      } else {
        entry.target.classList.remove("inview");
      }
    });
  };
  const io = new IntersectionObserver(active);

  for (let i = 0; i < items.length; i++) {
    io.observe(items[i]);
  }
}

function setFadeUpList() {
  const items = document.querySelectorAll(".fade-up-list");

  const active = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("inview-list");
      } else {
        entry.target.classList.remove("inview-list");
      }
    });
  };
  const io2 = new IntersectionObserver(active);
  for (let i = 0; i < items.length; i++) {
    io2.observe(items[i]);
  }
}
