<template>
  <SplashScreen />
  <div id="cookies" ref="cookies"></div>
  <a href="#main" class="skip-nav">Aller au contenu</a>
  <Header />
  <transition name="page-opacity" mode="out-in">
    <div v-if="$screen.width < 992" class="logo-mobile">
      <img
        alt="Yanis Kocher - Développeur web à Strasbourg"
        src="@/assets/logo/large-color.svg"
        class="color w-100"
        width="208" height="32.11"
      />
      <img
        alt="Yanis Kocher - Développeur web à Strasbourg"
        src="@/assets/logo/large-white.svg"
        class="white w-100"
        width="208" height="32.11"
      />
    </div>
  </transition>
  <CookiesBanner v-if="localStorageValue == null" @save-cookies-choices="saveCookiesChoice" />
  <router-view id="main" v-slot="{ Component }">
    <transition name="page-opacity" mode="out-in">
      <component :is="Component" @save-cookies-choice="saveCookiesChoice" />
    </transition>
  </router-view>
  <Footer />
</template>

<script>
import SplashScreen from "./components/SplashScreen.vue";
import CookiesBanner from "./components/CookiesBanner.vue";
import Header from "./components/layout/HeaderView.vue";
import Footer from "./components/layout/FooterView.vue";
import SitemapGeneratorVue from './components/SitemapGenerator.vue';

let
 metricoolScript, gtmScript, gtagScript;
metricoolScript = document.createElement("script");
gtmScript = document.createElement("script");
gtagScript = document.createElement("script");

metricoolScript.innerHTML =
  'function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"6bad90aa454290576f74075752151bda"})});';
gtmScript.setAttribute(
  "src",
  "https://www.googletagmanager.com/gtag/js?id=G-S30RGL4SJR"
);
gtmScript.async = true;
gtagScript.innerHTML =
  'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag("js", new Date()); gtag("config", "G-S30RGL4SJR");';

export default {
  components: {
    SplashScreen,
    CookiesBanner,
    Header,
    Footer,
  },

  data() {
    return {
      localStorageValue: localStorage.getItem("acceptCookies"),
      acceptCookies: this.localStorageValue ?? false,
    };
  },

  created() {
    window.addEventListener("load", () => {
      const splashscreen = document.querySelector("#splash-screen");
      splashscreen.classList.add("opacity-0");
      setTimeout(() => {
        splashscreen.classList.add("d-none");
      }, 201);
      document.body.classList.remove("overflow-hidden", "vh-100");
    });

    window.sitemap = () => {
      SitemapGeneratorVue.methods.sitemapGenerator.call(this);
    }
  },

  mounted() {
    window.addEventListener("resize", () =>
      setTimeout(() => this.$setFadeEffect(), 100)
    );
  },

  watch: {
    acceptCookies() {
      if (this.acceptCookies) {
        this.$refs.cookies.appendChild(metricoolScript);
        this.$refs.cookies.appendChild(gtmScript);
        this.$refs.cookies.appendChild(gtagScript);
      } else {
        while (this.$refs.cookies.firstChild) {
          this.$refs.cookies.removeChild(this.$refs.cookies.firstChild);
          location.reload();
        }
      }
    },
  },

  methods: {
    saveCookiesChoice(accepted) {
      this.acceptCookies = this.localStorageValue = accepted;
    },
  },
};
</script>

<style>
.page-opacity-enter-active,
.page-opacity-leave-active {
  transition: 300ms ease all;
}

.page-opacity-enter-from,
.page-opacity-leave-to {
  opacity: 0;
}
</style>
