<template>
  <header
    v-if="$screen.width >= 992"
    class="desktop position-fixed top-0 start-0 w-100 top"
    ref="header"
  >
    <div class="container h-100 d-flex align-items-center">
      <div class="col-3">
        <router-link :to="{ name: 'home' }" class="logo">
          <img
            alt="Yanis Kocher - Développeur web | création de site internet à Strasbourg"
            src="@/assets/logo/large-color.svg"
            class="color h-100"
            width="207.22" height="32"
          />
          <img
            alt="Yanis Kocher - Développeur web | création de site internet à Strasbourg"
            src="@/assets/logo/large-white.svg"
            class="white h-100"
            width="207.22" height="32"
          />
        </router-link>
      </div>

      <nav class="col-6 d-flex justify-content-center align-items-center">
        <router-link :to="{ name: 'home' }" @click="$event.target.blur()">Accueil</router-link>
        <router-link :to="{ name: 'services' }" @click="$event.target.blur()">Services</router-link>
        <router-link :to="{ name: 'prices' }" @click="$event.target.blur()">Tarifs</router-link>
        <router-link :to="{ name: 'blog' }" @click="$event.target.blur()">Blog</router-link>
        <router-link :to="{ name: 'contact' }" @click="$event.target.blur()">Contact</router-link>
      </nav>
      <nav class="col-3 text-end">
        <router-link :to="{ name: 'contact' }" class="btn">Obtenir un devis</router-link>
      </nav>
    </div>
  </header>
  <header
    v-else
    class="mobile position-fixed bottom-0 start-0 w-100 bg-white"
    ref="header"
  >
    <nav
      class="container d-flex align-items-center justify-content-around w-100 h-100"
    >
      <router-link :to="{ name: 'home' }">
        <i class="fa-light fa-house-blank"></i>
        <p>Accueil</p>
      </router-link>
      <router-link :to="{ name: 'services' }">
        <i class="fa-light fa-computer"></i>
        <p>Services</p>
      </router-link>
      <router-link :to="{ name: 'prices' }">
        <i class="fa-light fa-tag"></i>
        <p>Tarifs</p>
      </router-link>
      <router-link :to="{ name: 'contact' }">
        <i class="fa-light fa-messages"></i>
        <p>Contact</p>
      </router-link>
      <!-- <router-link :to="{ name: 'contact' }">
        <i class="fa-light fa-file-invoice"></i>
        <p>Devis</p>
      </router-link> -->
    </nav>
  </header>
</template>

<script>
export default {
  name: "HeaderView",

  mounted() {
    window.addEventListener("scroll", () => {
      this.handleScrollForHeader();
      this.handleScrollForFooter();
    });
  },

  unmounted() {
    window.removeEventListener("scroll", () => {
      this.handleScrollForHeader();
      this.handleScrollForFooter();
    });
  },

  watch: {
    $route() {
      this.handleScrollForHeader();
    },
  },

  methods: {
    handleScrollForHeader() {
      if (this.$screen.width >= 992 && this.$refs.header) {
        if (window.scrollY == 0) this.$refs.header.classList.add("top");
        else this.$refs.header.classList.remove("top");
      }
    },

    handleScrollForFooter() {
      if (this.$screen.width < 992 && this.$refs.header) {
        let footer = document.querySelector("footer");

        if (window.scrollY + window.innerHeight >= footer.offsetTop) {
          this.$refs.header.style.transform = "translateY(100%)";
        } else this.$refs.header.style.transform = "translateY(0)";
      }
    },
  },
};
</script>
