<template>
  <nav id="breadcrumb" class="d-flex">
    <div v-for="(link, i) in links" :key="i">
      <router-link
        v-if="i < links.length - 1"
        :to="setParamsOnLink(link.path)"
        >{{ link.meta.title }}</router-link
      >
      <span v-else class="text-primary" aria-current="page">
        {{ link.meta.title }}
      </span>
      <span v-if="i < links.length - 1" class="mx-2 mx-md-3">/</span>
    </div>
  </nav>
</template>

<script>
export default {
  name: "BreadCrumb",
  data() {
    return {
      links: [{ meta: { title: "Accueil" }, path: "/" }],
    };
  },

  created() {
    this.createBreadCrumb();
  },

  watch: {
    $route() {
      this.createBreadCrumb();
    },
  },

  methods: {
    createBreadCrumb() {
      this.links = this.links.concat(this.$route.matched);
      this.links.forEach((firstLink, i) => {
        this.links.forEach((secondLink, j) => {
          if (i !== j && firstLink.path == secondLink.path)
            this.links.splice(j, 1);
        });
      });
    },

    setParamsOnLink(path) {
      if (path.includes("/:")) {
        Object.keys(this.$route.params).forEach(
          (param) =>
            (path = path.replace(`:${param}`, this.$route.params[param]))
        );
      }

      return path;
    },
  },
};
</script>
