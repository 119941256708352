<template>
  <div id="splash-screen" class="align-items-center justify-content-center">
    <div class="text-center">
      <img src="@/assets/logo/small-color.svg" class="w-50" alt="" />
      <p class="mt-4">
        Chargement de la page
        <span class="dots">
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SplashScreen",
};
</script>
