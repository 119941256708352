import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import moment from "moment";
import api from "./api";
import * as bootstrap from "bootstrap";
import VueScreen from "vue-screen";
import * as globalFunctions from "./global.js";

const app = createApp(App).use(router).use(VueScreen, "bootstrap");

app.config.globalProperties.$api = api;
app.config.globalProperties.$bootstrap = bootstrap;

//import components
import BreadCrumb from "./components/BreadCrumb.vue";
app.component("BreadCrumb", BreadCrumb);

//import global variables
app.config.globalProperties.$setFadeEffect = globalFunctions.setFadeEffect;

app.mount("#app");
moment.locale("fr");
