<template>
  <footer class="position-relative">
    <div class="gradiant position-absolute bottom-100 start-0"></div>
    <div class="container col-12 col-lg-9 col-lg-12">
      <div class="d-flex justify-content-between">
        <div class="col-12 col-lg-4 mb-5 mb-lg-0">
          <router-link :to="{ name: 'home' }" class="logo">
            <div class="m-0 h-100">
              <img
                alt="Yanis Kocher - Développeur web à Strasbourg"
                src="@/assets/logo/large-white.svg"
                class="white h-100"
                width="207.22" height="32"
              />
            </div>
          </router-link>
          <p
            class="col-11 col-sm-9 col-lg-12 mx-auto mx-lg-0 text-center text-lg-start"
          >
            Yanis Kocher, développeur web freelance à Strasbourg spécialisé dans la création
            de sites internet et CMS. Contactez-moi pour réaliser
            votre projet.
          </p>
          <p v-if="$screen.width >= 992" class="copyright">
            Tous droits réservés - Yanis Kocher
          </p>
        </div>
        <div v-if="$screen.width >= 768" class="navigation">
          <h2>Navigation</h2>
          <nav>
            <router-link :to="{ name: 'home' }">Accueil</router-link>
            <router-link :to="{ name: 'services' }"
              >Découvrir les services</router-link
            >
            <router-link :to="{ name: 'prices' }">Voir les tarifs</router-link>
            <router-link :to="{ name: 'blog' }">Visiter mon blog</router-link>
            <router-link :to="{ name: 'contact' }">Me contacter</router-link>
          </nav>
        </div>
        <div v-if="$screen.width >= 768" class="help">
          <h2>Besoin d'aide ?</h2>
          <nav>
            <router-link :to="{ name: 'faq' }">Foire aux questions</router-link>
            <router-link :to="{ name: 'legals' }">Mentions légales</router-link>
            <router-link :to="{ name: 'conditions' }">C.G.V / C.G.U</router-link>
            <router-link :to="{ name: 'cookies' }">Cookies</router-link>
          </nav>
        </div>
        <div v-else class="w-100 d-flex justify-content-around">
          <div class="navigation">
            <h2>Navigation</h2>
            <nav>
              <router-link :to="{ name: 'home' }">Accueil</router-link>
              <router-link :to="{ name: 'services' }"
                >Découvrir les services</router-link
              >
              <router-link :to="{ name: 'prices' }"
                >Voir les tarifs</router-link
              >
              <router-link :to="{ name: 'blog' }">Visiter mon blog</router-link>
              <router-link :to="{ name: 'contact' }">Me contacter</router-link>
            </nav>
          </div>
          <div class="help">
            <h2>Besoin d'aide ?</h2>
            <nav>
              <router-link :to="{ name: 'faq' }"
                >Foire aux questions</router-link
              >
              <router-link :to="{ name: 'legals' }">Mentions légales</router-link>
              <router-link :to="{ name: 'conditions' }">C.G.V / C.G.U</router-link>
              <router-link :to="{ name: 'cookies' }">Cookies</router-link>
            </nav>
          </div>
        </div>
        <div class="contact mt-5 mt-md-0">
          <h2 v-if="$screen.width >= 768">Information de contact</h2>
          <nav>
            <div class="d-flex justify-content-center justify-content-md-start">
              <i class="fa-solid fa-phone pt-1"></i>
              <div>
                <a href="tel:0781999003" class="m-0">+33 7 81 99 90 03</a>
                Lu-Ve de 10h à 17h
              </div>
            </div>
            <div
              class="d-flex align-items-center mt-3 justify-content-center justify-content-md-start"
            >
              <i class="fa-solid fa-paper-plane"></i>
              <a href="mailto:contact@yaniskocher.fr" class="m-0"
                >contact@yaniskocher.fr</a
              >
            </div>
            <div
              class="social-media d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <a
                href="https://www.linkedin.com/in/yanis-kocher/"
                target="_blank"
                aria-label="Lien vers Linkedin"
                ><i class="fa-brands fa-linkedin"></i
              ></a>
              <a href="https://github.com/YanisKocher" target="_blank" aria-label="Lien vers Github"
                ><i class="fa-brands fa-github"></i
              ></a>
              <a href="https://twitter.com/YanisKocher" target="_blank" aria-label="Lien vers Twitter"
                ><i class="fa-brands fa-x-twitter"></i
              ></a>
              <a href="https://www.instagram.com/yanis.kocher.dev/" target="_blank" aria-label="Lien vers Instagram">
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a href="https://www.youtube.com/@YanisKocher" target="_blank" aria-label="Lien vers Youtube"><i class="fa-brands fa-youtube"></i></a>
              <a href="https://www.tiktok.com/@yanis.kocher.dev" target="_blank" aria-label="Lien vers TikTok"><i class="fa-brands fa-tiktok"></i></a>
              <a href="https://www.threads.net/@yanis.kocher.dev" target="_blank" aria-label="Lien vers Threads">
                <i class="fa-brands fa-threads"></i>
              </a>
            </div>
          </nav>
          <p v-if="$screen.width < 768" class="copyright text-center">
            Tous droits réservés - Yanis Kocher
          </p>
        </div>
      </div>
      <p
        v-if="$screen.width >= 768 && $screen.width < 992"
        class="copyright text-center mt-5"
      >
        Tous droits réservés - Yanis Kocher
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterView",
};
</script>
