import { createRouter, createWebHistory } from "vue-router";
import api from "../api";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView"),
    meta: {
      titleFunction: () => {
        return "Accueil";
      },
      title: "",
      description:
        "Yanis Kocher, développeur web freelance à Strasbourg spécialisé dans la création de sites internet. Faites confiance à son expertise en développement web, programmation et optimisation SEO pour des projets de qualité. Contactez Yanis Kocher pour la création de votre site web personnalisé et performant.",
      indexed: true,
    },
    sitemap: {
      lastmod: '2023-10-20T15:13:11+01:00',
      priority: 0.8
    }
  },
  {
    path: "/services",
    name: "services",
    component: () => import("../views/ServicesView"),
    meta: {
      titleFunction: () => {
        return "Services";
      },
      title: "",
      description:
        "Découvrez les services de développement web proposés par Yanis Kocher, développeur web freelance spécialisé dans la création de sites internet sur mesure. Faites confiance à mon expertise pour concevoir des solutions digitales adaptées à vos besoins et améliorer votre présence en ligne.",
      indexed: true,
    },
    sitemap: {
      lastmod: '2023-10-20T15:13:11+01:00',
      priority: 0.9
    }
  },
  {
    path: "/tarifs",
    name: "price_template",
    component: () => import("../views/Prices/PriceTemplate"),
    meta: {
      titleFunction: () => {
        return "Tarifs";
      },
      title: "",
      description: "",
      indexed: true,
    },
    children: [
      {
        path: "",
        name: "prices",
        component: () => import("../views/Prices/PricesView"),
        meta: {
          titleFunction: () => {
            return "Tarifs";
          },
          title: "",
          description:
            "Découvrez les différents packs d'offres que je vous propose, vous permettant de répondre au mieux à vos attentes. Si vous ne trouvez pas votre bonheur, vous pouvez toujours réaliser un devis sur mesure !",
          indexed: true,
        },
        sitemap: {
          lastmod: '2023-10-20T15:13:11+01:00',
          priority: 1
        }
      },
      {
        path: ":slug",
        name: "summary",
        component: () => import("../views/Prices/SummaryView"),
        meta: {
          titleFunction: (params) => {
            return getFullNameWithSlug(params.slug);
          },
          title: "",
          description: "",
          indexed: false,
        },
      },
    ],
  },
  {
    path: "/blog",
    name: "blog_template",
    component: () => import("../views/Blog/BlogTemplate"),
    meta: {
      titleFunction: () => {
        return "Blog";
      },
      title: "",
      description: "",
      indexed: true,
    },
    children: [
      {
        path: "",
        name: "blog",
        component: () => import("../views/Blog/BlogView"),
        meta: {
          titleFunction: () => {
            return "Blog";
          },
          title: "",
          description:
            "Découvrez des articles traitant de multiples sujets, mais tous concernant le développement web ainsi que les sites internet !",
          indexed: true,
        },
        sitemap: {
          lastmod: '2023-10-20T15:13:11+01:00',
          priority: 1
        }
      },
      {
        path: ":slug",
        name: "article",
        component: () => import("../views/Blog/ArticleView"),
        meta: {
          titleFunction: async (params) => {
            let data = null;
            await api.get(`articles/${params.slug}`).then((resp) => data = resp.data);
            return data ?? getFullNameWithSlug(params.slug);
          },
          title: "",
          description: "",
          indexed: true,
        },
      },
    ],
  },
  {
    path: "/foire-aux-questions",
    name: "faq",
    component: () => import("../views/FAQView"),
    meta: {
      titleFunction: () => {
        return "Foire aux questions";
      },
      title: "",
      description:
        "Retrouvez toutes mes réponses à vos questions concernant la création de votre projet web.",
      indexed: true,
    },
    sitemap: {
      lastmod: '2023-10-20T15:13:11+01:00',
      priority: 1
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView"),
    meta: {
      titleFunction: () => {
        return "Contact";
      },
      title: "",
      description:
        "Contactez-moi pour discuter de vos besoins en développement web, que ce soit pour la création d'un site web personnalisé, l'optimisation de votre site existant ou la résolution de problèmes techniques.",
      indexed: true,
    },
    sitemap: {
      lastmod: '2023-10-20T15:13:11+01:00',
      priority: 0.9
    }
  },
  // {
  //   path: "/devis",
  //   name: "quotation",
  //   component: () => import("../views/QuotationView"),
  //   meta: {
  //     titleFunction: () => {
  //       return "Créez votre devis";
  //     },
  //     title: "",
  //     description:
  //       "Créez votre devis sur mesure pour répondre au mieux à votre projet web.",
  //     indexed: true,
  //   },
  // },
  {
    path: "/mentions-legales",
    name: "legals",
    component: () => import("../views/LegalsView"),
    meta: {
      titleFunction: () => {
        return "Mentions légales";
      },
      title: "",
      description: "",
      indexed: false,
    },
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () => import("../views/CookiesView"),
    meta: {
      titleFunction: () => {
        return "Gestion des cookies";
      },
      title: "",
      description: "",
      indexed: false,
    },
  },
  {
    path: "/conditions-generales",
    name: "conditions",
    component: () => import("../views/ConditionsView"),
    meta: {
      titleFunction: () => {
        return "Conditions générales d'utilisation / de vente";
      },
      title: "",
      description: "",
      indexed: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/NotFoundView"),
    meta: {
      titleFunction: () => {
        return "Page non trouvée";
      },
      title: "",
      description: "",
      indexed: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: "smooth",
          });
        }, 500);
      });
    }
    return {
      top: 0,
      behavior: "instant",
    };
  },
});

const suffix = "Yanis Kocher - Développeur web freelance à Strasbourg";

router.beforeEach(async (to, from, next) => {
  await Promise.all(to.matched.map(async (route) => {
    const data = await route.meta.titleFunction(to.params, to.query);

    if(typeof data == 'object') {
      route.meta.title = data.title;
      to.data = data;
    } else {
      route.meta.title = data;
    }
  }));  

  if (to.name == "home") {
    to.meta.title = suffix;
    document.title = suffix;
  } else {
    to.meta.title = to.matched[to.matched.length - 1].meta.title;
    if (to.meta.title) document.title = `${to.meta.title} | ${suffix}`;
  }

  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", to.meta.description);
  document
    .querySelector('meta[name="robots"]')
    .setAttribute("content", to.meta.indexed ? "all" : "noindex");
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", document.title);
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", to.meta.description);
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.origin + to.path);

  from.name
    ? setTimeout(() => (document.body.id = to.name), 300)
    : (document.body.id = to.name);

  next();
});

const getFullNameWithSlug = function (slug) {
  let str = slug.replaceAll("-", " ");
  str = str.charAt(0).toUpperCase() + str.slice(1);

  composedWords.forEach((word) => {
    if (str.includes(word)) str = str.replace(word, word.replace(" ", "-"));
  });

  properNames.forEach((pName) => {
    if (str.includes(pName.toLowerCase()))
      str = str.replace(pName.toLowerCase(), pName);
  });

  return str;
};

const properNames = ["Vuejs", "Laravel", "Wordpress", "Prestashop", "CMS"];
const composedWords = ["e commerce"];

export default router;
